.head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #282c34;
    color: white;
}

.gauche{
    position:relative;
    right:50px;

}