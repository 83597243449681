.container{
    column-count: 2;
    column-gap:50px;
    break-inside: avoid;
    -webkit-column-break-inside: avoid;
    -webkit-backface-visibility: hidden;
    page-break-inside: avoid;
}

.leftSpace{
    margin-left: 15px;
}