.card{
    border-radius: 10px;
    width:100%;
    text-align: center;
    background-color: white;
    color:black;
    padding: 1px;
    margin-bottom: 10px;
}

.hover-button--on {
    display: inline;
    cursor:pointer;
}
